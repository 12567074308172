<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Recent Deposits</span>
          <v-spacer></v-spacer>
          <v-btn small class="text-xs" color="primary darken-2">View All</v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(data,index) in deposit"
              :key="data.img"
              :class="`d-flex px-0 ${index > 0 ? 'mt-2':''}`"
            >
              <v-img
                contain
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-semibold success--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>

      <v-divider
        class="my-sm-5 mx-3"
        :vertical="$vuetify.breakpoint.smAndUp"
      ></v-divider>

      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Recent Withdrawals</span>
          <v-spacer></v-spacer>
          <v-btn small class="text-xs" color="success darken-2">View All</v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(data,index) in withdraw"
              :key="data.img"
              :class="`d-flex px-0 ${index > 0 ? 'mt-2':''}`"
            >
              <v-img
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-semibold error--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
      <v-divider
        class="my-sm-5 mx-3"
        :vertical="$vuetify.breakpoint.smAndUp"
      ></v-divider>

      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Recent Interrupts</span>
          <v-spacer></v-spacer>
          <v-btn small class="text-xs" color="error darken-2">View All</v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(data,index) in interrupt"
              :key="data.img"
              :class="`d-flex px-0 ${index > 0 ? 'mt-2':''}`"
            >
              <v-img
                max-height="40"
                max-width="40"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-semibold error--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  setup() {
    const deposit = [
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Dorolice Crossman',
        subtitle: 'House Rents',
        amount: '+45,000 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Stella Ganderton',
        subtitle: 'Monthly Server Hosting',
        amount: '+5,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'Genevra Honeywood',
        subtitle: 'New IPhone 10',
        amount: '+18,700 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Harmonia Nisius',
        subtitle: 'Savings for shopping',
        amount: '+40,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'De Falloon',
        subtitle: 'Developping My Platform',
        amount: '+2,000 frs',
      },
    ]
    const withdraw = [
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Dorolice Crossman',
        subtitle: 'House Rents',
        amount: '-145,000 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Stella Ganderton',
        subtitle: 'Monthly Server Hosting',
        amount: '-235,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'Genevra Honeywood',
        subtitle: 'New IPhone 10',
        amount: '-218,700 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Harmonia Nisius',
        subtitle: 'Savings for shopping',
        amount: '-230,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'De Falloon',
        subtitle: 'Developping My Platform',
        amount: '-122,000 frs',
      },
    ]
    const interrupt = [
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Dorolice Crossman',
        subtitle: 'House Rents',
        amount: '-1,450,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'Genevra Honeywood',
        subtitle: 'New IPhone 10',
        amount: '-180,700 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Harmonia Nisius',
        subtitle: 'Savings for shopping',
        amount: '-450,000 frs',
      },
      {
        img: require('@/assets/images/avatars/1.png'),
        title: 'Stella Ganderton',
        subtitle: 'Monthly Server Hosting',
        amount: '-540,000 frs',
      },
      {
        img: require('@/assets/images/avatars/2.png'),
        title: 'De Falloon',
        subtitle: 'Developping My Platform',
        amount: '-2,100,000 frs',
      },
    ]

    return {
      deposit,
      withdraw,
      interrupt,
    }
  },
}
</script>
